import React from "react"
import get from 'lodash/get'
import BlockContent from '@sanity/block-content-to-react'
import { graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { SocialIcon } from '../components/ds/atoms/icons'
import MoreMentors from "../components/ds/organisms/more-mentors"

const MentorProfilePageTemplate = ({ pageContext, data, location }) => {
  const footerData = get(data, 'sanityFooter')
  const mentor = get(data, 'sanityMentor')
  const locale = get(pageContext, 'locale')

  const bio = get(mentor, ['bio', locale])
  const role = get(mentor, ['role', locale])
  const description = locale === 'sv' ? get(mentor, 'description._rawSv') : get(mentor, 'description._rawEn')

  const name = `${get(mentor, 'firstname')} ${get(mentor, 'lastname')}`

  const image = get(mentor, 'avatar.asset.localFile.childImageSharp.gatsbyImageData')

  const serializers = {}

  return (
    <>
      <SEO
        title={name}
        description={bio}
        lang={get(pageContext, 'locale')}
        pathname={get(location, 'pathname')}
      />
      <Layout locale={get(pageContext, 'locale')} footer footerContent={footerData}>
        <div className="bg-white overflow-hidden">
          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
            <div className="hidden lg:block bg-cool-grey-50 absolute top-0 bottom-0 left-3/4 w-screen"></div>
            <div className="mx-auto text-base max-w-prose lg:max-w-none">
              <p className="text-base leading-6 text-vivid-light-blue-600 font-semibold tracking-wide uppercase">{role}</p>
              <h1 className="mt-2 mb-8 text-3xl leading-8 font-extrabold tracking-tight text-cool-grey-900 sm:text-4xl sm:leading-10">{name}</h1>
            </div>
            <div className="lg:grid lg:grid-cols-2 lg:gap-8">
              <div className="relative mb-8 lg:mb-0 lg:row-start-1 lg:col-start-2">
                <svg className="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384">
                  <defs>
                    <pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="80" height="105" patternUnits="userSpaceOnUse">
                      <g fillRule="evenodd">
                        <g id="death-star" className="text-cool-grey-100" fill="currentColor">
                          <path d="M20 10a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V10zm15 35a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zM20 75a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V75zm30-65a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V10zm0 65a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V75zM35 10a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10zM5 45a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zm0-35a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10zm60 35a5 5 0 0 1 10 0v50a5 5 0 0 1-10 0V45zm0-35a5 5 0 0 1 10 0v20a5 5 0 0 1-10 0V10z" />
                        </g>
                      </g>
                    </pattern>
                  </defs>
                  <rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
                </svg>
                <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                  <figure>
                    <div className="relative pb-5 lg:pb-0">
                      <GatsbyImage className="rounded-lg shadow-lg object-cover object-center absolute inset-0 w-full h-full lg:static lg:h-auto" image={image} alt={name} />
                    </div>
                  </figure>
                </div>
              </div>
              <div>
                <div className="text-base max-w-prose mx-auto lg:max-w-none">
                  <p className="text-lg leading-7 text-cool-grey-500 mb-5">{bio}</p>
                </div>
                <div className="prose text-cool-grey-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1 mb-5">
                  <BlockContent blocks={description} serializers={serializers} />
                </div>
                <ul className="flex space-x-5 justify-center lg:justify-start">
                  {mentor.socialLinks.map(link => {
                    return (
                      <li key={link._key}>
                        <a href={link.profileUrl} target="_blank" rel="noreferrer" className="text-cool-grey-400 hover:text-cool-grey-500 transition ease-in-out duration-150">
                          <span className="sr-only">{link.type}</span>
                          <SocialIcon type={link.type} />
                        </a>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <MoreMentors locale={locale} currentMentorId={mentor._id} />
      </Layout>
    </>
  )
}

export default MentorProfilePageTemplate

export const query = graphql`
  query GetMentor($id: String, $locale: String) {
    sanityMentor(_id: {eq: $id}) {
      _id
      firstname
      lastname
      role {
        en
        sv
      }
      socialLinks {
        _key
        profileUrl
        type
      }
      description {
        _rawEn
        _rawSv
      }
      bio {
        en
        sv
      }
      avatar {
        asset {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1024
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
    sanityFooter(locale: {eq: $locale}) {
      copyright
      languageSwitcherLabel
      mission
      links {
        _key
        headline
        links {
          _key
          label
          slug
        }
      }
      newsletter {
        headline
        subline
        buttonText
        emailField {
          type
          placeholder
          label
          identifier
          errors {
            _key
            key
            message
          }
        }
        postSubmitRedirectSlug
        toastError {
          headline
          description
        }
      }
      socialLinks {
        _key
        profileUrl
        type
      }
      logo {
        asset {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
